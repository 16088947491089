<template>
  <fieldset class="px-3 flex-grow-1">
    <legend>{{ $t(`enums.assets.${asset}`) }}</legend>

    <v-text-field
      v-if="isOther"
      v-model.trim="typeOther"
      :label="$t('views.inventory.typeOther')"
      class="px-0"
      :rules="typeOtherRule"
    />

    <v-text-field
      v-model.trim="referenceNo"
      :label="$t('views.inventory.referenceNo')"
      class="px-0"
      :rules="referenceNoRule"
    />

    <v-radio-group
      v-model="condition"
      class="mt-0"
      :label="$t('views.inventory.condition')"
      :rules="conditionRule"
      row
      dense
    >
      <v-radio
        v-for="n in 5"
        :key="n"
        :label="n.toString()"
        :value="n"
        :color="conditionColors[n - 1]"
      />
    </v-radio-group>

    <v-radio-group
      v-model="roadSide"
      class="mt-0"
      :label="$t('views.inventory.roadSide')"
      :rules="roadSideRule"
      row
      dense
    >
      <v-radio
        :label="$t('enums.side.0')"
        value="left"
      />
      <v-radio
        :label="$t('enums.side.1')"
        value="right"
      />
    </v-radio-group>

    <label>{{ $t('views.inventory.roadSideDistance') }}</label>
    <v-range-slider
      v-model="roadSideDistance"
      :max="max"
      min="0"
      dense
      thumb-label
    />

    <label>{{ $t('views.inventory.vehicleDistance') }}</label>
    <v-range-slider
      v-model="vehicleDistance"
      max="10"
      min="0"
      dense
      thumb-label
    />
  </fieldset>
</template>

<script>
import { mapState } from 'vuex'

import { assetTypes } from '@/utils/enum'
import { rules } from '@/utils/rules'

export default {
  name: 'AssetsFieldset',

  props: {
    asset: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      typeOther: null,
      referenceNo: null,
      condition: null,
      roadSide: null,
      roadSideDistance: [0, 10],
      vehicleDistance: [0, 10],
      conditionColors: ['#05a57d', '#33cc99', '#ffbd21', '#ff9400', '#ff6666'],

      conditionRule: rules.inventory.condition,
      roadSideRule: rules.inventory.roadSide,
      referenceNoRule: rules.inventory.referenceNo,
      typeOtherRule: rules.inventory.typeOther,
    }
  },

  computed: {
    ...mapState('iri', ['segment']),

    max() {
      return this.segment?.roadWidth || 10
    },

    isOther() {
      return this.asset === assetTypes.OTHER
    },
  },

  methods: {
    collectData() {
      const result = {
        assetId: this.asset,
        data: {
          referenceNo: this.referenceNo,
          condition: this.condition,
          roadSide: this.roadSide,
          roadSideDistance: this.roadSideDistance,
          vehicleDistance: this.vehicleDistance,
        },
      }

      if (this.isOther) {
        result.data.typeOther = this.typeOther
      }

      return result
    },

    setValues(data) {
      this.referenceNo = data.referenceNo
      this.condition = data.condition
      this.roadSide = data.roadSide
      this.roadSideDistance = data.roadSideDistance
      this.vehicleDistance = data.vehicleDistance

      if (this.isOther) {
        this.typeOther = data.typeOther
      }
    },
  },
}
</script>
