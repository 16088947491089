import axios from 'axios'

export default {
  async createDefect({ commit }, payload) {
    try {
      const { data } = await axios.post('/inventory/defects', payload)

      if (!payload.imageId || !data._id || !payload.defects) {
        throw new Error('Invalid defect data.')
      }

      commit('updateDefects', {
        imageId: payload.imageId,
        defectId: data._id,
        data: payload.defects,
      })
    } catch (error) {
      console.error('Error creating defect:', error)
    }
  },

  async deleteDefect({ state, commit }, imageId) {
    try {
      const defectId = state.defects[imageId]?.id

      if (!defectId) {
        throw new Error('Invalid defect ID.')
      }

      await axios.delete(`/inventory/defects/${defectId}`)
    } catch (error) {
      console.error('Error deleting defect:', error)
    }

    commit('removeDefect', imageId)
  },

  async fetchDefects({ commit }, measurementId) {
    try {
      const params = { measurementId }

      const { data } = await axios.get('/inventory/defects', { params })

      for (const defect of data) {
        if (!defect.image || !defect._id || !defect.defects) {
          throw new Error('Invalid defect data.')
        }

        commit('updateDefects', {
          imageId: defect.image,
          defectId: defect._id,
          data: defect.defects,
        })
      }
    } catch (error) {
      console.error('Error fetching defects:', error)
    }
  },

  async updateDefect({ commit }, payload) {
    try {
      const { defectId } = payload
      const defects = payload.defectsData

      if (!payload.imageId || !payload.defectId || !defects) {
        throw new Error('Invalid defect data.')
      }

      await axios.put(`/inventory/defects/${defectId}`, { defects })

      commit('updateDefects', {
        imageId: payload.imageId,
        defectId: payload.defectId,
        data: defects,
      })
    } catch (error) {
      console.error('Error updating defect:', error)
    }
  },

  async createAsset({ commit }, payload) {
    try {
      const { data } = await axios.post('/inventory/assets', payload)

      if (!payload.imageId || !data._id || !payload.assets) {
        throw new Error('Invalid asset data.')
      }

      commit('updateAssets', {
        imageId: payload.imageId,
        assetId: data._id,
        data: payload.assets,
      })
    } catch (error) {
      console.error('Error creating asset:', error)
    }
  },

  async deleteAsset({ state, commit }, imageId) {
    try {
      const assetId = state.assets[imageId]?.id

      if (!assetId) {
        throw new Error('Invalid asset ID.')
      }

      await axios.delete(`/inventory/assets/${assetId}`)
    } catch (error) {
      console.error('Error deleting asset:', error)
    }
    commit('removeAsset', imageId)
  },

  async fetchAssets({ commit }, measurementId) {
    try {
      const params = { measurementId }

      const { data } = await axios.get('/inventory/assets', { params })

      for (const asset of data) {
        if (!asset.image || !asset._id || !asset.assets) {
          throw new Error('Invalid asset data.')
        }

        commit('updateAssets', {
          imageId: asset.image,
          assetId: asset._id,
          data: asset.assets,
        })
      }
    } catch (error) {
      console.error('Error fetching assets:', error)
    }
  },

  async updateAsset({ commit }, payload) {
    try {
      const { assetId } = payload
      const assets = payload.assetsData

      if (!payload.imageId || !payload.assetId || !assets) {
        throw new Error('Invalid asset data.')
      }

      await axios.put(`/inventory/assets/${assetId}`, { assets })

      commit('updateAssets', {
        imageId: payload.imageId,
        assetId: payload.assetId,
        data: assets,
      })
    } catch (error) {
      console.error('Error updating asset:', error)
    }
  },
}
