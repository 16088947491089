import resetState from '@/utils/resetState'
import { initialInventoryState } from './state'

export default {
  removeDefect(state, imageId) {
    delete state.defects[imageId]
  },

  updateDefects(state, { imageId, defectId, data }) {
    state.defects[imageId] = { id: defectId, defects: data }
  },

  removeAsset(state, imageId) {
    delete state.assets[imageId]
  },

  updateAssets(state, { imageId, assetId, data }) {
    state.assets[imageId] = { id: assetId, assets: data }
  },

  clearState(state) {
    resetState(state, initialInventoryState)
  },
}
