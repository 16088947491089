<template>
  <material-card
    outlined
    class="fill-height"
  >
    <core-callout
      :title="$t('views.charts.tableDataPoint.title')"
      :subtitle="$t('views.charts.tableList.description')"
    >
      <v-btn
        color="primary"
        icon
        :download="`${segment.name}_10m.csv`"
        :href="toCsv(segment)"
        @click.stop
      >
        <v-icon>$fas fa-download</v-icon>
      </v-btn>
    </core-callout>
    <v-data-table
      dense
      :headers="iriHeaders"
      :items="segment.iriSections"
      item-key="startStation"
      fixed-header
      style="overflow: auto"
      height="100%"
      hide-default-footer
      disable-pagination
      @mouseover:row="(event, { item }) => selectSection(item)"
      @click:row="(event, { item }) => selectSection(item)"
      @mouseleave:row="(event, { item }) => unselectSection(item)"
    >
      <template #[`item.startStation`]="{ item }">
        <v-icon :color="riskColor(item.RUni)"> $fas fa-map-marker-alt </v-icon>
        {{ item.startStation }}
      </template>
      <template #[`item.endStation`]="{ item }">
        {{ item.endStation }}
      </template>
      <template #[`item.iri`]="{ item }">
        {{ item.iri ? item.iri.toFixed(2) : '-' }}
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import { arrayToCsv } from '@/utils/csv'
import * as enums from '@/utils/enum'

export default {
  name: 'InventoryTable',

  props: {
    segment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      iriHeaders: [
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
      ],

      csvHeader: [
        {
          text: this.$t('enums.headers.name'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.lat'),
          value: 'endLatitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.lon'),
          value: 'endLongitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    riskColor(risk) {
      switch (risk) {
        case enums.risk.HR:
          return 'error'
        case enums.risk.AR:
          return 'warning'
        case enums.risk.LR:
          return 'success'
        default:
          return '#999999'
      }
    },

    selectSection(item) {
      this.$emit('section-selected', item)
    },

    unselectSection() {
      this.$emit('section-selected', undefined)
    },

    toCsv(segment) {
      return (
        'data:text/csv;charset=utf-8,' +
        encodeURI(
          arrayToCsv(
            this.csvHeader,
            segment.iriSections.map((s) => ({
              name: segment.name,
              startStation: s.startStation,
              endStation: s.endStation,
              endLatitude: s.endLocation.coordinates[1],
              endLongitude: s.endLocation.coordinates[0],
              iri: s.iri,
            })),
          ),
        )
      )
    },
  },
}
</script>
