<template>
  <material-card
    outlined
    class="fill-height"
  >
    <core-callout
      :title="$t('views.charts.tableDataPoint.title')"
      :subtitle="$t('views.charts.tableList.description')"
    >
      <span v-if="isInventoryManager">
        <v-btn
          color="primary"
          icon
          @click.stop="goToDefects(segment)"
        >
          <v-icon>$fas fa-triangle-exclamation</v-icon>
        </v-btn>
        <!-- <v-btn
          color="primary"
          icon
          @click.stop="goToAssets(segment)"
        >
          <v-icon>$fas fa-signs-post</v-icon>
        </v-btn> -->
      </span>
      <v-btn
        color="primary"
        icon
        @click.stop="goToIriImageUpload(segment)"
      >
        <v-icon>$fas fa-file-circle-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="showImageBtn"
        color="primary"
        icon
        @click.stop="$emit('click:image')"
      >
        <v-icon>$fas fa-film</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        :download="`${segment.name}_${granularity}m.csv`"
        :href="toCsv(segment)"
        @click.stop
      >
        <v-icon>$fas fa-download</v-icon>
      </v-btn>
    </core-callout>
    <v-data-table
      dense
      :headers="iriHeaders"
      :items="segment.iriSections"
      item-key="startStation"
      fixed-header
      style="overflow: auto"
      height="100%"
      hide-default-footer
      disable-pagination
      @mouseover:row="(event, { item }) => selectSection(item)"
      @click:row="(event, { item }) => selectSection(item)"
      @mouseleave:row="(event, { item }) => unselectSection(item)"
    >
      <template #[`item.startStation`]="{ item }">
        <v-icon :color="riskColor(item.RUni)"> $fas fa-map-marker-alt </v-icon>
        {{ item.startStation }}
      </template>
      <template #[`item.endStation`]="{ item }">
        {{ item.endStation }}
      </template>
      <template #[`item.iri`]="{ item }">
        {{ item.iri ? item.iri.toFixed(2) : '-' }}
      </template>
      <template #[`item.iriL`]="{ item }">
        {{ item.iriL ? item.iriL.toFixed(2) : '-' }}
      </template>
      <template #[`item.iriR`]="{ item }">
        {{ item.iriR ? item.iriR.toFixed(2) : '-' }}
      </template>
      <template #[`item.mpd`]="{ item }">
        {{ item.mpd ? item.mpd.toFixed(2) : '-' }}
      </template>
      <template #[`item.REiriVpC3_ET`]="{ item }">
        {{ item.REiriVpC3_ET ? item.REiriVpC3_ET.toFixed(2) : '-' }}
        <v-icon
          v-if="iriIconShow(item)"
          color="error"
          >$fas fa-xmark</v-icon
        >
      </template>
      <template #[`item.REiriVpC3_1`]="{ item }">
        {{ item.REiriVpC3_1 ? item.REiriVpC3_1.toFixed(2) : '-' }}
      </template>
      <template #[`item.REiriVpC3_2`]="{ item }">
        {{ item.REiriVpC3_2 ? item.REiriVpC3_2.toFixed(2) : '-' }}
      </template>
      <template #[`item.REiriVpC3_3`]="{ item }">
        {{ item.REiriVpC3_3 ? item.REiriVpC3_3.toFixed(2) : '-' }}
      </template>
      <template #[`item.REiriVpC3M`]="{ item }">
        {{ item.REiriVpC3M ? item.REiriVpC3M.toFixed(2) : '-' }}
      </template>
      <template #[`item.REiriCVCpC3D2`]="{ item }">
        {{ item.REiriCVCpC3D2 ? item.REiriCVCpC3D2.toFixed(2) : '-' }}
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import { arrayToCsv } from '@/utils/csv'
import * as enums from '@/utils/enum'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TableList',
  props: {
    segment: {
      type: Object,
      required: true,
    },
    showImageBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      iriHeaders: [
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.IRIL'),
          value: 'iriL',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.IRIR'),
          value: 'iriR',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.MPD'),
          value: 'mpd',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.range'),
          value: 'REiriVpC3_ET',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.pass1'),
          value: 'REiriVpC3_1',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.pass2'),
          value: 'REiriVpC3_2',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.pass3'),
          value: 'REiriVpC3_3',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.mean'),
          value: 'REiriVpC3M',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.offset'),
          value: 'REiriCVCpC3D2',
          sortable: false,
        },
      ],
      csvHeader: [
        {
          text: this.$t('enums.headers.name'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.lat'),
          value: 'endLatitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.lon'),
          value: 'endLongitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('iri', ['granularity']),
    ...mapGetters('iri', ['device', 'vehicle']),
    ...mapGetters(['userHasRole']),

    isInventoryManager() {
      return this.userHasRole(enums.roles.INVENTORY_MANAGER)
    },
  },
  methods: {
    iriIconShow(iri) {
      return (
        iri.REiriCVCpC3D2 &&
        iri.REiriVpC3_ET &&
        Math.abs(iri.REiriCVCpC3D2) < iri.REiriVpC3_ET
      )
    },
    riskColor(risk) {
      switch (risk) {
        case enums.risk.HR:
          return 'error'
        case enums.risk.AR:
          return 'warning'
        case enums.risk.LR:
          return 'success'
        default:
          return '#999999'
      }
    },
    selectSection(item) {
      this.$emit('section-selected', item)
    },
    unselectSection() {
      this.$emit('section-selected', undefined)
    },
    toCsv(segment) {
      return (
        'data:text/csv;charset=utf-8,' +
        encodeURI(
          arrayToCsv(
            this.csvHeader,
            segment.iriSections.map((s) => ({
              name: segment.name,
              startStation: s.startStation,
              endStation: s.endStation,
              endLatitude: s.endLocation.coordinates[1],
              endLongitude: s.endLocation.coordinates[0],
              iri: s.iri,
            })),
          ),
        )
      )
    },

    goToIriImageUpload(segment) {
      const path = `/dashboard/iri-images/${segment._id}`
      this.$router.push({ path })
    },

    goToDefects(segment) {
      const path = `/dashboard/inventory/defects/${segment._id}`
      this.$router.push({ path })
    },

    goToAssets(segment) {
      const path = `/dashboard/inventory/assets/${segment._id}`
      this.$router.push({ path })
    },
  },
}
</script>
