<template>
  <v-card
    style="overflow: hidden"
    outlined
  >
    <v-img
      :class="(!selected ? 'blurry' : '') + ' align-center'"
      :src="src"
      class="grey darken-4 flex-grow-1"
      contain
      max-height="95%"
    />
    <div class="d-flex flex-row align-center">
      <v-btn
        icon
        depressed
        :disabled="previousDisabled"
        class="inline"
        @click="previous"
        ><v-icon>$fas fa-caret-left</v-icon></v-btn
      >
      <span class="mx-4"
        >{{ selectedIndex + 1 }} / {{ items.length || 1 }}</span
      >
      <v-btn
        icon
        depressed
        :disabled="nextDisabled"
        class="inline"
        @click="next"
        ><v-icon>$fas fa-caret-right</v-icon></v-btn
      >
      <span class="mx-4"
        >Section: {{ selected?.startStation }} -
        {{ selected?.endStation }}</span
      >
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'ImageViewer',

  data: () => ({
    items: [],
    selected: null,
    selectedIndex: 0,
    src: '',
  }),

  computed: {
    nextDisabled() {
      return (
        this.items.length === 0 || this.selectedIndex >= this.items.length - 1
      )
    },
    previousDisabled() {
      return this.items.length === 0 || this.selectedIndex <= 0
    },
  },

  watch: {
    selected() {
      this.setCurrentImage(this.selected)
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    ...mapMutations('images', ['setCurrentImage']),

    openSingle(image) {
      this.items = []
      this.selectedIndex = 0

      this.selected = image
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${image._id}`
    },

    openMultiple(images, index) {
      if (index >= images.length || index < 0) return
      this.items = images
      this.selectedIndex = index

      this.selected = images[index]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${images[index]._id}`
    },

    next() {
      if (this.nextDisabled) return

      this.selectedIndex++
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
    },

    previous() {
      if (this.previousDisabled) return

      this.selectedIndex--
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
    },

    handleKeyDown(event) {
      if (event.key === 'ArrowLeft') {
        this.previous()
      } else if (event.key === 'ArrowRight') {
        this.next()
      }
    },
  },
}
</script>
