import { geoJSON } from 'leaflet'
import { center, lineString, nearestPointOnLine, points } from '@turf/turf'

import { iriRunToFeatureCollection } from '@/utils/datapoint'

export default {
  computed: {
    iriMixin_iriRunGeo() {
      return this.iriRun ? iriRunToFeatureCollection([this.iriRun]) : []
    },

    iriMixin_selectedSectionGeo() {
      if (!this.selectedSection) {
        return null
      } else {
        return {
          type: 'Feature',
          properties: {
            risk: this.selectedSection.RUni,
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              this.selectedSection.startLocation.coordinates,
              this.selectedSection.endLocation.coordinates,
            ],
          },
        }
      }
    },

    iriMixin_orderedImages() {
      if (!this.iriRun?.iriSections10m) return []

      let sections = this.iriRun.iriSections10m
      let path = lineString(
        sections.map(
          (s) =>
            center(
              points([s.startLocation.coordinates, s.endLocation.coordinates]),
            ).geometry.coordinates,
        ),
      )

      return this.images
        .filter((i) => i?.datapoint?.location?.coordinates?.length > 0)
        .map((i) => {
          let iriSectionindex = nearestPointOnLine(
            path,
            i.datapoint.location.coordinates,
          ).properties.index

          return {
            ...i,
            endStationInt: sections[iriSectionindex].endStationInt,
            endStation: sections[iriSectionindex].endStation,
            startStation: sections[iriSectionindex].startStation,
            iri: sections[iriSectionindex].iri,
          }
        })
        .sort((a, b) => a.endStationInt - b.endStationInt)
    },
  },

  methods: {
    iriMixin_sectionSelected(item) {
      this.selectedSection = item
    },

    iriMixin_setBounds() {
      const bounds = geoJSON(this.iriMixin_iriRunGeo).getBounds()

      if (bounds && bounds.isValid()) {
        this.mapBindings.bounds = geoJSON(this.iriMixin_iriRunGeo).getBounds()
      }
    },
  },
}
